import 'picturefill';
import 'lity';
import events from './modules/events';
import lozad from 'lozad';

const observer = lozad();
observer.observe();

export default [
  events()
];
