import $ from 'jquery';

export default () => {
  $(function () {
    if (navigator.userAgent.indexOf('Android') > 0) {
      $('body').addClass('view-android');
    };

    $('.l-header_humberger').on('click', function () {
      $(this).toggleClass('js-active');
      $('.l-header').toggleClass('js-active');
      $('body').toggleClass('js-fix');
    });

    $('.mv_menu_logo').on('click', function () {
      $(this).toggleClass('js-active');
      $('.mv_menu_gnav').toggleClass('js-active');
      $('.l-overlay').toggleClass('js-active');
    });

    function close () {
      $('.mv_menu_logo').removeClass('js-active');
      $('.mv_menu_gnav').removeClass('js-active');
      $('.l-overlay').removeClass('js-active');
    }

    $('.l-overlay').on('click', function () {
      close();
    });

    $(window).on('scroll', function () {
      close();
    });
  });
};
